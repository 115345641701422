var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "disable-item": _vm.loading } },
    [
      _c(
        "CForm",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.sendEmail.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "form-row mb-3 table-info py-2 rounded" },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "col-2 col-sm-1 col-form-label d-none d-sm-flex justify-content-center",
                      attrs: { for: "to" },
                    },
                    [
                      _c("CIcon", {
                        attrs: { name: "cil-bolt", height: "20" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    {
                      staticClass: "d-flex flex-column",
                      attrs: { cols: "12", sm: "11" },
                    },
                    [
                      _c("ul", { staticClass: "checklist" }, [
                        _c("li", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.isTask,
                                expression: "isTask",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox", id: "checkIsTask" },
                            domProps: {
                              checked: Array.isArray(_vm.isTask)
                                ? _vm._i(_vm.isTask, null) > -1
                                : _vm.isTask,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.isTask,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.isTask = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.isTask = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.isTask = $$c
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "checkIsTask" },
                            },
                            [_vm._v(" Task? ")]
                          ),
                        ]),
                        _vm.isTask
                          ? _c("li", { staticClass: "form-check" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.isMyTask,
                                    expression: "isMyTask",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "checkAssignMyself",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.isMyTask)
                                    ? _vm._i(_vm.isMyTask, null) > -1
                                    : _vm.isMyTask,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.isMyTask,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.isMyTask = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.isMyTask = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.isMyTask = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "checkAssignMyself" },
                                },
                                [_vm._v(" Assign myself the task ")]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("transition", { attrs: { name: "fade" } }, [
                !_vm.isMyTask
                  ? _c(
                      "div",
                      { staticClass: "form-row mb-3" },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "col-2 col-sm-1 col-form-label d-none d-sm-flex justify-content-center",
                            attrs: { for: "to" },
                          },
                          [
                            _c("CIcon", {
                              attrs: { name: "cil-at", height: "20" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "CCol",
                          { attrs: { cols: "12", sm: "11" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "first_name",
                                options: _vm.users,
                                placeholder: "Recipients",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "option",
                                    fn: function (user) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              `${user.first_name} <${user.email}>`
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1199427577
                              ),
                              model: {
                                value: _vm.to,
                                callback: function ($$v) {
                                  _vm.to = $$v
                                },
                                expression: "to",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                !_vm.isMyTask
                  ? _c(
                      "div",
                      { staticClass: "form-row mb-3" },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "col-2 col-sm-1 col-form-label d-none d-sm-flex justify-content-center",
                            attrs: { for: "cc" },
                          },
                          [
                            _c("CIcon", {
                              attrs: { name: "cil-cc", height: "20" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "CCol",
                          { attrs: { cols: "12", sm: "11" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "first_name",
                                options: _vm.users,
                                placeholder: "Type user name or email",
                                multiple: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "option",
                                    fn: function (user) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              `${user.first_name} <${user.email}>`
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1199427577
                              ),
                              model: {
                                value: _vm.cc,
                                callback: function ($$v) {
                                  _vm.cc = $$v
                                },
                                expression: "cc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "form-row mb-3" },
                [
                  _c("label", {
                    staticClass:
                      "col-2 col-sm-1 col-form-label d-none d-sm-flex justify-content-center",
                    attrs: { for: "cc" },
                  }),
                  _c(
                    "CCol",
                    { attrs: { cols: "12", sm: "11" } },
                    [
                      _c("CInput", {
                        staticClass: "compose-input",
                        attrs: {
                          placeholder: "Subject",
                          value: _vm.subject,
                          lazy: false,
                          "was-validated": "",
                          required: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.subject = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-row mb-3" },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "col-2 col-sm-1 col-form-label d-none d-sm-flex justify-content-center",
                      attrs: { for: "to" },
                    },
                    [
                      _c("CIcon", {
                        attrs: { name: "cil-restaurant", height: "20" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { cols: "12", sm: "11" } },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.restaurants,
                          placeholder: "Select a restaurant..",
                        },
                        model: {
                          value: _vm.restaurant,
                          callback: function ($$v) {
                            _vm.restaurant = $$v
                          },
                          expression: "restaurant",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-row mb-3" },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "col-2 col-sm-1 col-form-label d-none d-sm-flex justify-content-center",
                      attrs: { for: "cc" },
                    },
                    [
                      _c("CIcon", {
                        attrs: { name: "cil-tags", height: "20" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { cols: "12", sm: "11" } },
                    [
                      _c("LabelDropdown", {
                        attrs: { labels: _vm.labels },
                        on: {
                          "update:labels": function ($event) {
                            _vm.labels = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "CRow",
            [
              _c("CCol", { staticClass: "ml-auto", attrs: { sm: "11" } }, [
                _c(
                  "div",
                  { staticClass: "form-group reply" },
                  [
                    _c("quill-editor", {
                      attrs: {
                        options: { placeholder: "Write your message here" },
                      },
                      model: {
                        value: _vm.reply,
                        callback: function ($$v) {
                          _vm.reply = $$v
                        },
                        expression: "reply",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group d-flex" },
                  [
                    _c(
                      "CButton",
                      {
                        staticClass: "mr-1",
                        attrs: {
                          type: "submit",
                          color: "info",
                          disabled: _vm.loading,
                        },
                      },
                      [
                        _c("CIcon", { attrs: { name: "cil-send" } }),
                        _vm._v(" Send "),
                      ],
                      1
                    ),
                    _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: "Discard",
                            expression: "'Discard'",
                          },
                        ],
                        staticClass: "mr-1 ml-auto",
                        attrs: {
                          color: "danger",
                          variant: "outline",
                          to: { name: "Inbox" },
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-trash" } })],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CElementCover",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { opacity: 0.4 },
        },
        [
          _c("CSpinner", {
            attrs: { size: "5xl", grow: "", color: "primary" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }