var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CDropdown",
    {
      attrs: { color: "light" },
      scopedSlots: _vm._u([
        {
          key: "toggler-content",
          fn: function () {
            return [
              _c("CIcon", { attrs: { name: "cil-tag" } }),
              _c(
                "CBadge",
                {
                  staticClass: "ml-1",
                  attrs: { color: _vm.selectedLabel.color },
                },
                [_vm._v(_vm._s(_vm.selectedLabel.label))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    _vm._l(_vm.$globalVar.emailLabels, function (el, index) {
      return _c(
        "CDropdownItem",
        { key: index, on: { click: () => (_vm.emailLabels = el.value) } },
        [
          _c("CBadge", { attrs: { color: el.color } }, [
            _vm._v(_vm._s(el.label)),
          ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }